<template>
<v-col cols="12" class="py-0 bg-brilliant">
 
  <v-row style="padding-bottom:70px;">
    <v-col cols="11" sm="12" md="12" lg="12" class="mx-auto  header-section text-center mt-0 mb-5">
                
        <v-img src="../assets/img/monnaie_pirate_logo.png" class="d-none d-sm-inline-flex" width="230"/>
        <v-img src="../assets/img/monnaie_pirate_logo.png" class="d-inline-flex d-sm-none" width="150"/>

        <h1 class="font-main pb-1 pt-0 mb-5 pb-5 yellow--text text--darken-2">
          La monnaie pirate
        </h1>

    </v-col>

    <v-col class="mx-auto bg-paper text-center my-5 py-5 pt-2"
           cols="11" sm="11" md="10" lg="8"  style="border-radius:8px;">
      <v-row>
        <v-col cols="12" class="pb-0">
          <h3 class="font-hand clocker pb-1 pt-0 mt-5 pb-0 red--text text--darken-2">
            <i>Lancement dans</i>
          </h3>
        </v-col>
        <v-col cols="6" class="text-right pr-0 pt-0">
          <h2 class="mb-0 font-hand clocker red--text text--darken-1" style="">
            {{ $store.state.app.clockerD }}J 
            · {{ $store.state.app.clockerH }}h · 
          </h2>
        </v-col>
        <v-col cols="6" class="text-left pt-0">
          <h2 class="mb-0 font-hand clocker red--text text--darken-1" style="">
            {{ $store.state.app.clockerM }}m 
            · {{ $store.state.app.clockerS }}sec
          </h2>
        </v-col>
      </v-row>
    </v-col>

    <v-col class="mx-auto text-center my-5 py-5 pt-2"
            cols="11" sm="11" md="10" lg="8"  style="border-radius:8px;">
      <v-btn class="ma-1" icon large target="_blank" href="https://www.facebook.com/profile.php?id=100094049551423">
        <v-icon>mdi-facebook</v-icon>
      </v-btn>
      <v-btn class="ma-1" icon large target="_blank" href="https://www.instagram.com/la_monnaie_pirate/">
        <v-icon>mdi-instagram</v-icon>
      </v-btn>
      <v-btn class="ma-1" icon large target="_blank" href="https://discord.gg/aQ34zjvFmC">
        <img src="../assets/img/homepage/discord-w.png" class="d-block" height="28" />
      </v-btn>
    </v-col>

    <v-col cols="11" sm="11" md="10" lg="8" 
          class="mx-auto bg-paper font-papyrus text-paper" 
          style="margin-top:45px;">
          
        <small class="d-block text-center">
          <v-img src="../assets/img/monnaie_pirate_logo.png" class="mx-auto d-none d-sm-block" width="80" style="margin-top:-80px"/>
          <v-img src="../assets/img/monnaie_pirate_logo.png" class="mx-auto d-sm-none" width="60" style="margin-top:-50px"/>
          <i>~ Synopsis ~</i>
        </small>
        <br>
        L'histoire raconte qu'un jour, depuis les tréfonds du Royaume des Morts, un couple de pirates inventa une nouvelle monnaie révolutionnaire,
        dans le but de pirater le système capitaliste, qui commençait sérieusement à menacer l'existence même du monde des Vivants.
        <br><br>
        A force d'observer l'humanité se développer, année après année, ils avaient commencé à imaginer un nouveau système hors du système,
        avec de nouvelles règles, et de nouveaux objectifs.
        <br><br>
        Leur but était de créer un système économique plus égalitaire et plus stable que tout ce qui avait existé auparavant.
        Et grâce à la puissance d'internet, cette bande de pirates avait finit par trouver la formule mathématique qui allait tout changer.
        
        <small class="d-block text-center mt-5">
          [...]
        </small>
    </v-col>


  </v-row>

  <v-row>
    <v-col class="mx-auto bg- text-center my-5 py-5 pt-5"
          cols="11" sm="7" md="6" lg="3"  style="border-radius:8px;">
          <img src="../assets/img/homepage/coffrepirate.png" width="300" style="max-width:100%;"/>
          <br>
        <i class="primary--text" style="font-size:23px;">
          Seras-tu assez malin pour<br>
          trouver le mot de passe<br>
          qui te permettra d'accéder<br>
          à la Monnaie Pirate<br>
          avant tout le monde ?
        </i>
        
        <v-text-field class="mt-5" v-model="pwd" label="Mot de passe ?" hide-details
                      outlined placeholder="Mot de passe ?" @keyup.enter.exact="tryPwd()">
        </v-text-field>
        <v-btn color="primary" @click="tryPwd()" block class="mt-3 black--text">
          <v-icon>mdi-key</v-icon>
        </v-btn>
        <v-alert v-if="pwdError" color="red" class="mt-3 font-papyrus">
          Hmm... Hmm...<br>Désolé, mais c'est pas ça...<br>
          Essaie encore !
        </v-alert>
        <br>
        <small class="grey--text font-papyrus">
          <b>Indice</b> : tous les pirates connaissent<br>cette formule depuis leur plus tendre enfance...
        </small>
    </v-col>
  </v-row>

  <v-row>
    <v-col class="pa-0">
      <Footer v-if="showFooter"/>
    </v-col>
  </v-row>

</v-col>
</template>

<style>
</style>

<script>

import '@/assets/css/home.css';

import Footer from '@/components/main-layout/Footer.vue'
import router from "../router/router"

const config = require('../config/' + process.env.NODE_ENV)

export default {
  name: 'home',
  components: {
    Footer
  },
  data: () => ({
    showTopInfoBar: true,
    showFooter: true,
    plans: [],
    pwd: "",
    pwdError: false
  }),
  mounted: async function(){
    this.$store.dispatch('app/incStat', '/soon')
    this.$store.dispatch('app/fetchNbUsersTotal')
    this.$store.dispatch('app/startClocker')
  },
  methods: {
    closeTopInfoBar(){
      this.showTopInfoBar = false
    },
    tryPwd(){
      this.pwdError = false
      if(config.vipcodes.indexOf(this.pwd) > -1){
        localStorage.setItem("vipcode", this.pwd) 
        router.push("/unlocked")
      }
      else{
        this.pwdError = true
      }
    }
  },
}
</script>
